import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import ProcessItem, { ProcessIndex } from './kimlerIcin.style';

const KimlerIcinSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="KİMLER İÇİN?" />
          <Heading {...secHeading} content="✅ Hekimler" />
          <Heading {...secHeading} content="✅ Güzellik Merkezleri" />
          <Heading {...secHeading} content="✅ Kuaförler" />
          <Heading {...secHeading} content="✅ Diyetisyenler" />
          <Heading
            {...secHeading}
            content="✅ Randevu Sistemi ile Çalışan Lokal İşletmeler"
          />
        </Box>
      </Container>
    </Box>
  );
};

KimlerIcinSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
};

KimlerIcinSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ['30px', '50px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['14px', '17px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
};

export default KimlerIcinSection;
