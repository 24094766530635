import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/components/Accordion';
import Container from 'common/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';
import { Modal, Button as AntdButton, Form, Input, notification } from 'antd';
import 'antd/dist/antd.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FaqWrapper from './faq.style';
import axios from 'axios';

const FaqSection = ({
  sectionWrapper,
  row,
  col,
  secTitleWrapper,
  secHeading,
  secText,
  title,
  description,
  buttonWrapper,
  button,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        FAQ_DATA {
          title
          description
          expend
        }
      }
    }
  `);
  const [isOpen, setOpen] = useState(false);
  const onFinishContact = (values) => {
    axios
      .post('https://api.saderandevu.com/api/auth/send-contact', { ...values })
      .then((response) => {
        if (response.data.code === 1) {
          notification.success({
            message: 'Başarılı!',
            description: 'En kısa süre içerisinde size dönüş yapacağız!',
            placement: 'bottomRight',
          });
          setOpen(false);
        } else {
          notification.error({
            message: 'Başarısız!',
            description:
              'Şu anda bir problem yaşıyoruz. Lütfen daha sonra tekrar deneyiniz!',
            placement: 'bottomRight',
          });
          setOpen(false);
        }
      });
  };
  return (
    <Box {...sectionWrapper} id="faq_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="SIK SORULAN SORULAR" />
          <Heading {...secHeading} content="Aklınıza bir şey mi takıldı?" />
        </Box>
        <Box {...row}>
          <Box {...col}>
            <FaqWrapper>
              <Accordion>
                <>
                  {Data.saasModernJson.FAQ_DATA.map((accordionItem, index) => (
                    <AccordionItem
                      className="accordion_item"
                      key={`accordion-${index}`}
                      expanded={accordionItem.expend}
                    >
                      <>
                        <AccordionTitle className="accordion_title">
                          <>
                            <Heading {...title} content={accordionItem.title} />
                            <IconWrapper>
                              <OpenIcon>
                                <Icon icon={minus} size={18} />
                              </OpenIcon>
                              <CloseIcon>
                                <Icon icon={plus} size={18} />
                              </CloseIcon>
                            </IconWrapper>
                          </>
                        </AccordionTitle>
                        <AccordionBody className="accordion_body">
                          <Text
                            {...description}
                            content={accordionItem.description}
                          />
                        </AccordionBody>
                      </>
                    </AccordionItem>
                  ))}
                </>
              </Accordion>
            </FaqWrapper>
            <Box {...buttonWrapper}>
              <Button
                {...button}
                onClick={() => setOpen(true)}
                title="AKLINIZA TAKILAN BİR ŞEY DAHA MI VAR?"
              />
              <Modal
                width={600}
                title="İletişime Geç!"
                visible={isOpen}
                onCancel={() => setOpen(false)}
                footer={null}
              >
                <Form name="basic" onFinish={onFinishContact}>
                  <Form.Item
                    label="İsim"
                    name="name"
                    rules={[
                      { required: true, message: 'Lütfen isminizi giriniz!' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Telefon Numarası"
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: 'Lütfen telefon numaranızı giriniz!',
                      },
                    ]}
                  >
                    <PhoneInput
                      country={'tr'}
                      enableSearch={true}
                      countryCodeEditable={false}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Mesaj"
                    name="message"
                    rules={[{ required: true, message: 'Lütfen doldurunuz!' }]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item>
                    <AntdButton type="primary" htmlType="submit">
                      Gönder
                    </AntdButton>
                  </Form.Item>
                </Form>
                <br />
                <span style={{ fontWeight: 'bold' }}>E-posta</span>:{' '}
                <a href="mailto:destek@saderandevu.com">
                  destek@saderandevu.com
                </a>
                <br />
                <br />
                <span style={{ fontWeight: 'bold' }}>Adres</span>: 312 W 2nd St
                Unit #A1604 Casper, WY 82601, United States
              </Modal>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

FaqSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  buttonWrapper: PropTypes.object,
  button: PropTypes.object,
};

FaqSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['20px', '30px', '50px', '50px'],
    pb: ['60px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['55px', '65px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  col: {
    width: ['100%', '100%', '75%', '75%'],
  },
  title: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: 0,
  },
  description: {
    fontSize: '15px',
    color: 'textColor',
    lineHeight: '1.75',
    mb: 0,
  },
  buttonWrapper: {
    mt: '50px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default FaqSection;
