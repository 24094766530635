import Process1 from '../../assets/image/saasModern/process-1.svg';
import Process2 from '../../assets/image/saasModern/process-2.svg';
import Process3 from '../../assets/image/saasModern/process-3.svg';

import FeatureIcon1 from '../../assets/image/saasModern/icon-1.png';
import FeatureIcon2 from '../../assets/image/saasModern/icon-2.png';
import FeatureIcon3 from '../../assets/image/saasModern/icon-3.png';
import FeatureIcon4 from '../../assets/image/saasModern/icon-4.png';
import FeatureIcon5 from '../../assets/image/saasModern/icon-5.png';
import FeatureIcon6 from '../../assets/image/saasModern/icon-6.png';

import CiroEkran from '../../assets/image/saasModern/ciroekran.png';
import RandevularimEkran from '../../assets/image/saasModern/googleyorumu.png';
import RandevuAlEkran from '../../assets/image/saasModern/randevualekran.png';
import MusterilerimEkran from '../../assets/image/saasModern/cagirmaekranimusteri.png';

import AuthorOne from '../../assets/image/saasModern/author-1.jpg';
import AuthorTwo from '../../assets/image/saasModern/author-2.jpg';
import AuthorThree from '../../assets/image/saasModern/author-3.jpg';

import { ic_monetization_on } from 'react-icons-kit/md/ic_monetization_on';
import { users } from 'react-icons-kit/icomoon/users';
import { manWoman } from 'react-icons-kit/icomoon/manWoman';
import { clock } from 'react-icons-kit/icomoon/clock';

export const MENU_ITEMS = [
  {
    label: 'Ana Sayfa',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Faydaları',
    path: '#feature_section',
    offset: '0',
  },
  {
    label: 'Fiyatlandırma',
    path: '#pricing_section',
    offset: '0',
  },
  {
    label: 'Yorumlar',
    path: '#testimonial_section',
    offset: '0',
  },
  {
    label: 'SSS',
    path: '#faq_section',
    offset: '0',
  },
  {
    label: 'Hakkımızda',
    path: '#hakkimizda_section',
    offset: '0',
  },
];

export const PROCESS_ITEMS = [
  {
    image: Process2,
    title: 'Ücretsiz Kayıt Olun',
    description:
      'Kredi kartsız sadece telefon numaranızı ve şifrenizi yazarak 7 günlük ücretsiz denemenizi başlatın.',
  },
  {
    image: Process1,
    title: 'Randevunuzu Oluşturun',
    description:
      '10 saniye içerisinde ilk randevunuzu alın ve müşterilerinizi yönetin.',
  },
  {
    image: Process3,
    title: 'Yorum İsteyin',
    description:
      'Oluşan randevunun üstüne tıklayıp müşterinizden Google yorumu alın.',
  },
];

export const MONTHLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'İşletme Hesabı',
    description:
      'Randevularını, müşterilerini ve cirolarını takip edip daha fazla müşteri kazanmak isteyenler için.',
    price: '₺200',
    priceLabel: 'Aylık',
    buttonLabel: 'SATIN AL',
    url: 'https://buy.stripe.com/eVag1p1RAadjfpCbJ9',
    listItems: [
      {
        content:
          'Randevularınızı, cirolarınızı, müşterilerinizi ve giderlerinizi yönetin.',
      },
      {
        content:
          'Google yorum isteme özelliği sayesinde işletmenizi yukarılara taşıyın.',
      },
      {
        content:
          'Bir süredir randevu almayan müşterilerinizi kolayca görüp onları tek tuşla geri çağırın.',
      },
      {
        content:
          'Müşterilerinizi doğum günü kutlamaları ve parapuan özellikleriyle özel hissettirin.',
      },
      {
        content: 'Daha fazlası sizi içeride bekliyor...',
      },
    ],
  },
];

export const YEARLY_PRICING_TABLE = [
  {
    freePlan: true,
    name: 'Basic Account',
    description: 'For a single client or team who need to build website ',
    price: '$0',
    priceLabel: 'Only for first month',
    buttonLabel: 'CREATE FREE ACCOUNT',
    url: '#',
    listItems: [
      {
        content: 'Drag & Drop Builder',
      },
      {
        content: '1,000s of Templates Ready',
      },
      {
        content: 'Blog Tools',
      },
      {
        content: 'eCommerce Store ',
      },
      {
        content: '30+ Webmaster Tools',
      },
    ],
  },
];

export const FAQ_DATA = [
  {
    expend: true,
    title: 'SadeRandevu nedir?',
    description:
      'SadeRandevu; bulut tabanlı randevu, ciro, müşteri yönetim sistemidir.',
  },
  {
    title: 'SadeRandevu ücretli midir?',
    description:
      'SadeRandevu size 7 günlük deneme sürümü sunar. Kayıt olduğunuz anda sisteme daha rahat alışabilmeniz için basitçe birkaç örnek ile müşterinizi, cironuzu, borçlarınızı ve giderlerinizi yerleştirir. Size sadece sistemi kolayca incelemek ve uygulamak kalacaktır.',
  },
  {
    title: 'Asistanlarım bu programı kullanabilirler mi?',
    description:
      'SadeRandevu; asistanlara, danışmanlara, stajerlere ve siz patronlara özel çözümler sunar. Kolayca ayarlardan onlara özel hesaplar oluşturarak yetkilerini verebilirsiniz.',
  },
  {
    title: 'Sorun yaşarsam kime ulaşacağım?',
    description:
      'SadeRandevu; destek hattını sürekli aktif tutmaya çalışmaktadır. Platform içerisinden kolayca yetkililerle iletişime geçebilirsiniz.',
  },
  {
    title: 'Verilerim ne kadar güvende?',
    description:
      'Verilerinizi en son teknolojileri kullanarak yüksek güvenlikli Amazon sunucularında saklıyoruz.',
  },
  {
    title: 'Kullanıcı deneyimine önem veriyor musunuz?',
    description:
      'SadeRandevu; her geçen gün müşterilerinden aldığı dönütlere göre kendini geliştirir.',
  },
  {
    title: 'SadeRandevu ile müşterilerimi nasıl artıracağım?',
    description:
      'SadeRandevu; yaptığınız işlemler sonucunda elde ettiği verileri en faydalı şekilde size sunar. Bu veriler doğrultusunda size sunduğu pazarlama çözümleri; Google Yorumu alarak online müşteri kazanımı, Toplu SMS gönderme, Çağır Özelliği ile uzun süredir randevu almayan müşterilerinizi geri kazanmanıza yardımcı olur.',
  },
  {
    title: 'SadeRandevu bilgisayara kurulan bir program mı?',
    description:
      'SadeRandevu kurulum gerektirmez çünkü bulut tabanlı bir yazılımdır. İnternetin olduğu her yerden ve her cihazdan(telefon,bilgisayar,tablet) bağlanabilirsiniz.',
  },
  {
    title: 'Ne sıklıkla bakım yapıyorsunuz ve işlerimi aksatacak mısınız?',
    description:
      'SadeRandevu;  Gece saat 00:00 ile 06:00 arasında bakıma girer. Çözülmesi gereken acil bir problem oluştuğunda ise gün içinde 15 dakikalık tek seferlik bakımlarımız olmaktadır. İşlerinizi aksatmamak için hassasiyetle çalışıyoruz.',
  },
];

export const FOOTER_WIDGET = [
  {
    title: 'Hızlı Linkler',
    menuItems: [
      {
        url: '#',
        text: 'İletişime Geçin',
      },
      {
        url: '#',
        text: 'Bizimle Çalışın',
      },
    ],
  },
  {
    title: 'Dökümanlar',
    menuItems: [
      {
        url: '#',
        text: 'Kullanıcı Sözleşmesi',
      },
      {
        url: '#',
        text: 'Gizlilik Politikası',
      },
      {
        url: '#',
        text: 'KVKK',
      },
      {
        url: '#',
        text: 'GDPR',
      },
    ],
  },
];

export const FEATURES = [
  {
    icon: FeatureIcon1,
    title: 'Randevularınızı Yönetin',
    description:
      'Aynı ekran üzerinde birkaç saniye içinde hızlıca randevularınızı oluşturun, yorum isteyin, cirolarınızı düzenleyin ve notlar ekleyerek müşterilerinizi yönetin. Artık oradan oraya gidip zaman kaybetmenize gerek yok. Asistanınıza kolay ve sade bir kullanıcı deneyimi sunun.',
  },
  {
    icon: FeatureIcon2,
    title: 'Olumlu Google Yorumları Alın',
    description:
      'Türkiye’de bir ilk olan Google Yorumu isteme özelliği ile mutlu müşterilerinizden yorumlar isteyin. İşletmenizin yorum sayısı arttıkça Google’da potansiyel müşterileriniz sizi reklamsız olarak daha çok görecek ve tercih edecektir. Peki ya siz en iyi yorumlu işletmeleri tercih etmez miydiniz?',
  },
  {
    icon: FeatureIcon3,
    title: 'Müşteri Sadakatini Arttırın',
    description:
      'Sizin belirlediğiniz oranlar kadar müşterilerinize ParaPuan tanımlayarak müşteri sadakatini arttırın.',
  },
  {
    icon: FeatureIcon4,
    title: 'Müşterilerinizi Yönetin',
    description:
      'Müşterilerinizin geçmişe dönük randevularını, hangilerine geldiğini, neler yaptırdığını, toplam ne kadar kazandırdığını, kaç gündür randevu almadığını, borcunu, doğum gününü ve daha bir çok bilgiyi kolayca görün.',
  },
  {
    icon: FeatureIcon5,
    title: 'SMS Pazarlamayla Cirolarınızı Artırın',
    description:
      "Toplu ve Bireysel SMS hizmeti dışında Yorum, Hatırlatma ve Çağırma SMS'leri gibi birçok alternatif SMS pazarlama yöntemini kullanarak cirolarınızı artırın.",
  },
  {
    icon: FeatureIcon6,
    title: 'Anında Destek Alın',
    description:
      'Herhangi bir sorun veya zorluk yaşarsanız en kısa sürede ulaşarak yardımcı oluyoruz. Sizden aldığımız geri bildirimler sayesinde SadeRandevu deneyiminizi bir üst seviyeye taşıyın.',
  },
];

export const SCREENSHOTS = [
  {
    icon: clock,
    title: 'Randevu Alma Sayfası',
    image: RandevuAlEkran,
  },
  {
    icon: manWoman,
    title: 'Google Yorumu',
    image: RandevularimEkran,
  },
  {
    icon: users,
    title: 'Müşterileriniz',
    image: MusterilerimEkran,
  },
  {
    icon: ic_monetization_on,
    title: 'Ciro Raporları',
    image: CiroEkran,
  },
];

export const TESTIMONIALS = [
  {
    title: 'İzmir’in En Yüksek Yorumlu Kuaförü Oldum',
    review:
      'SadeRandevu yazılımını kullanmaya başlamadan önce Google işletme hesabımda sadece 4 adet yorumum vardı. Bir yıl geçmeden 200 den fazla yoruma ulaştım. Bu pozitif yorumlar sayesinde her gün Google üzerinden reklamsız yeni müşteriler kazanıyorum. Bir çok farklı program kullandım fakat bu özellikleri sağlayan bir yazılım görmedim.',
    name: 'Sadettin Yılmaz',
    designation: 'Bayan Kuaförü',
    avatar: `${AuthorOne}`,
  },
  {
    title:
      'Yeni Hastalara Ulaşıyor ve Randevularımı Kolaylıkla Yönetebiliyorum',
    review:
      'Eski kullandığım karmaşık programları asistanlarım kullanamazdı. SadeRandevu arayüzü o kadar sade ve kullanışlı ki saniyeler içinde randevularımı düzenleyebiliyorum. Hastalarıma doktor kimliğimle ve adımla gönderdiğim hatırlatma mesajları ise kliniğimin prestijini arttırıyor. Mutlu hastalarımdan Google yorumları almaya başladığımdan beri randevularımız arttı.',
    name: 'Berk Erol',
    designation: 'Diş Hekimi',
    avatar: `${AuthorTwo}`,
  },
  {
    title: 'Aramayla Randevu Hatırlatabiliyorum',
    review:
      'SadeRandevu artık müşterilerimi otomatik olarak randevudan 24 saat önce arıyor ve randevuyu hatırlatıyor. Artık müşterilerim randevularını kaçırmadığı için cirolarımı koruyorum.',
    name: 'Ayşen Mergen',
    designation: 'Güzellik Salonu Sahibi',
    avatar: `${AuthorThree}`,
  },
];
