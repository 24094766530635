import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import GlideCarousel from 'common/components/GlideCarousel';
import GlideSlide from 'common/components/GlideCarousel/glideSlide';

import PricingTable, {
  PricingHead,
  PricingPrice,
  PricingButton,
  PricingList,
  ListItem,
  PricingButtonWrapper,
} from './pricing.style';

import { checkmark } from 'react-icons-kit/icomoon/checkmark';

const PricingSection = ({
  sectionWrapper,
  row,
  secTitleWrapper,
  secHeading,
  secText,
  nameStyle,
  descriptionStyle,
  priceStyle,
  priceLabelStyle,
  buttonStyle,
  buttonFillStyle,
  listContentStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      saasModernJson {
        MONTHLY_PRICING_TABLE {
          name
          description
          price
          priceLabel
          buttonLabel
          url
          listItems {
            content
          }
        }
        YEARLY_PRICING_TABLE {
          name
          description
          price
          priceLabel
          buttonLabel
          url
          listItems {
            content
          }
        }
      }
    }
  `);

  const [state, setState] = useState({
    data: Data.saasModernJson.MONTHLY_PRICING_TABLE,
    active: true,
  });

  const data = state.data;
  const activeStatus = state.active;

  const pricingCarouselOptions = {
    type: 'slider',
    perView: 3,
    gap: 30,
    bound: true,
    breakpoints: {
      1199: {
        perView: 2,
        peek: {
          before: 100,
          after: 100,
        },
      },
      990: {
        perView: 1,
        peek: {
          before: 160,
          after: 160,
        },
      },
      767: {
        perView: 1,
        peek: {
          before: 80,
          after: 80,
        },
      },
      575: {
        perView: 1,
        gap: 15,
        peek: {
          before: 20,
          after: 20,
        },
      },
    },
  };

  return (
    <Box {...sectionWrapper} id="pricing_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="FİYATLANDIRMA" />
          <Heading
            {...secHeading}
            content="Karmaşaya Gerek Yok. Tek Fiyat, Aylık Ödeme"
          />
        </Box>
        <Box {...row}>
          {data.map((pricingTable, index) => (
            <PricingTable
              freePlan={pricingTable.freePlan}
              className="pricing_table"
            >
              {/*<PricingHead>*/}
              {/*<Heading content={pricingTable.name} {...nameStyle} />*/}
              {/*  <Text*/}
              {/*      content={pricingTable.description}*/}
              {/*      {...descriptionStyle}*/}
              {/*  />*/}
              {/*</PricingHead>*/}
              {/* <PricingPrice>
                <Text
                  content={pricingTable.price + ' / ' + pricingTable.priceLabel}
                  {...priceStyle}
                />
              </PricingPrice> */}
              <PricingList>
                <span style={{ fontSize: '16px' }}>
                  ✅ Randevularınızı, cirolarınızı, müşterilerinizi ve
                  giderlerinizi{' '}
                  <span style={{ fontWeight: 'bold' }}>yönetin</span>.
                </span>
                <br />
                <br />
                <span style={{ fontSize: '16px' }}>
                  ✅ Google yorum isteme özelliği sayesinde{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    yeni müşteriler kazanın
                  </span>
                  .
                </span>
                <br />
                <br />
                <span style={{ fontSize: '16px' }}>
                  ✅ Bir süredir randevu almayan müşterilerinizi kolayca görüp
                  tek tuşla{' '}
                  <span style={{ fontWeight: 'bold' }}>geri kazanın</span>.
                </span>
                <br />
                <br />
                <span style={{ fontSize: '16px' }}>
                  ✅ Doğum günü kutlamaları ve ParaPuan özellikleriyle{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    müşterilerinizi özel hissettirin
                  </span>
                  .
                </span>
                <br />
                <br />
                <span style={{ fontSize: '16px' }}>
                  ✅ Çok daha fazlası sizi içeride bekliyor...
                </span>
                {/*<Text content={'✔️Randevularınızı, cirolarınızı, müşterilerinizi ve giderlerinizi yönetin.'} {...listContentStyle} />*/}
                {/*<Text content={'✔️Google yorum isteme özelliği sayesinde işletmenizi yukarılara taşıyın.'} {...listContentStyle} />*/}
                {/*<Text content={'✔️Bir süredir randevu almayan müşterilerinizi kolayca görüp onları tek tuşla geri çağırın.'} {...listContentStyle} />*/}
                {/*<Text content={'✔️Müşterilerinizi doğum günü kutlamaları ve parapuan özellikleriyle özel hissettirin.'} {...listContentStyle} />*/}
                {/*<Text content={'✔️Daha fazlası sizi içeride bekliyor...'} {...listContentStyle} />*/}

                {
                  //   pricingTable.listItems.map((item, index) => (
                  //   <ListItem key={`pricing-table-list-${index}`}>
                  //     {/*<Icon*/}
                  //     {/*  icon={checkmark}*/}
                  //     {/*  className="price_list_icon"*/}
                  //     {/*  size={13}*/}
                  //     {/*/>*/}
                  //     <Text content={'✔️' + item.content} {...listContentStyle} />
                  //   </ListItem>
                  // ))
                }
              </PricingList>
              <PricingButton>
                <a href={pricingTable.url}>
                  {pricingTable.freePlan ? (
                    <Button title={pricingTable.buttonLabel} {...buttonStyle} />
                  ) : (
                    <Button
                      title={pricingTable.buttonLabel}
                      {...buttonFillStyle}
                    />
                  )}
                </a>
              </PricingButton>
            </PricingTable>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

PricingSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  nameStyle: PropTypes.object,
  descriptionStyle: PropTypes.object,
  priceStyle: PropTypes.object,
  priceLabelStyle: PropTypes.object,
  listContentStyle: PropTypes.object,
};

PricingSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['100px', '100px', '100px', '140px', '160px'],
    // pb: ['60px', '80px', '80px', '100px'],
  },
  row: {
    // flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  secTitleWrapper: {
    mb: ['10px', '25px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  col: {
    width: [1, 1 / 2, 1 / 2, 1 / 3],
    pr: '15px',
    pl: '15px',
  },
  nameStyle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '500',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    textAlign: 'center',
    mb: '12px',
  },
  descriptionStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  priceStyle: {
    as: 'span',
    display: 'block',
    fontSize: ['36px', '36px', '40px', '40px', '40px'],
    color: 'headingColor',
    textAlign: 'center',
    mb: '5px',
    letterSpacing: '-0.025em',
  },
  priceLabelStyle: {
    fontSize: ['13px', '14px', '14px', '14px', '14px'],
    color: 'textColor',
    lineHeight: '1.75',
    textAlign: 'center',
    mb: '0',
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    bg: '#fff',
    color: '#2aa275',
    colors: 'primaryWithBg',
    width: '222px',
    maxWidth: '100%',
  },
  buttonFillStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    color: 'white',
    borderRadius: '4px',
    pl: '10px',
    pr: '10px',
    colors: 'primaryWithBg',
    width: '400px',
    height: '50px',
    maxWidth: '100%',
  },
  listContentStyle: {
    fontSize: ['15px', '16px', '16px', '16px', '16px'],
    color: 'textColor',
    mb: '0',
  },
};

export default PricingSection;
