import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import Container from 'common/components/UI/Container';
import ProcessItem, { ProcessIndex } from './hakkimizda.style';
import Button from 'common/components/Button';
import { Button as AntdButton, Form, Input, Modal, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';

const HakkimizdaSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
  buttonWrapper,
  button,
}) => {
  const [isOpen, setOpen] = useState(false);
  const onFinishContact = (values) => {
    axios
      .post('https://api.saderandevu.com/api/auth/send-contact', { ...values })
      .then((response) => {
        if (response.data.code === 1) {
          notification.success({
            message: 'Başarılı!',
            description: 'En kısa süre içerisinde size dönüş yapacağız!',
            placement: 'bottomRight',
          });
          setOpen(false);
        } else {
          notification.error({
            message: 'Başarısız!',
            description:
              'Şu anda bir problem yaşıyoruz. Lütfen daha sonra tekrar deneyiniz!',
            placement: 'bottomRight',
          });
          setOpen(false);
        }
      });
  };
  return (
    <Box {...sectionWrapper} as="section" id="hakkimizda_section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text {...secText} content="HAKKIMIZDA" />
          <Heading
            {...secHeading}
            content="İnternet hizmetlerinde 10 yılı aşkın tecrübe ve profesyonel bir ekip ile işlerinizi büyütmek için çalışıyoruz. Sektörünüzde sizi rakipsiz kılacak ürün ve hizmetleri, sizlerden aldığımız talepler doğrultusunda işletmenizi bir üst seviyeye çıkartmak için geliştirdik. SadeRandevu yazılımı ile işletmenizi yönetmenize ve büyütmenize yardımcı oluyoruz."
          />
          <Box {...buttonWrapper}>
            <Button
              {...button}
              onClick={() => setOpen(true)}
              title="İletişime Geç!"
            />
            <Modal
              width={600}
              title="İletişime Geç!"
              visible={isOpen}
              onCancel={() => setOpen(false)}
              footer={null}
            >
              <Form name="basic" onFinish={onFinishContact}>
                <Form.Item
                  label="İsim"
                  name="name"
                  rules={[
                    { required: true, message: 'Lütfen isminizi giriniz!' },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="Telefon Numarası"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Lütfen telefon numaranızı giriniz!',
                    },
                  ]}
                >
                  <PhoneInput
                    country={'tr'}
                    enableSearch={true}
                    countryCodeEditable={false}
                  />
                </Form.Item>
                <Form.Item
                  label="Mesaj"
                  name="message"
                  rules={[{ required: true, message: 'Lütfen doldurunuz!' }]}
                >
                  <Input.TextArea />
                </Form.Item>
                <Form.Item>
                  <AntdButton type="primary" htmlType="submit">
                    Gönder
                  </AntdButton>
                </Form.Item>
              </Form>
              <br />
              <span style={{ fontWeight: 'bold' }}>E-posta</span>:{' '}
              <a href="mailto:destek@saderandevu.com">destek@saderandevu.com</a>
              <br />
              <br />
              <span style={{ fontWeight: 'bold' }}>Adres</span>: 312 W 2nd St
              Unit #A1604 Casper, WY 82601, United States
            </Modal>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

HakkimizdaSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  buttonWrapper: PropTypes.object,
};

HakkimizdaSection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ['60px', '100px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
  buttonWrapper: {
    mt: '15px',
    flexBox: true,
    justifyContent: 'center',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '600',
    borderRadius: '4px',
    pl: ['22px'],
    pr: ['22px'],
    colors: 'secondaryWithBg',
    minWidth: '150px',
  },
};

export default HakkimizdaSection;
