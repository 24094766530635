import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/components/Box';
import Text from 'common/components/Text';
import Container from 'common/components/UI/Container';
import Vimeo from '@u-wave/react-vimeo';

const VimeoVideoSection = ({
  sectionWrapper,
  secTitleWrapper,
  secText,
  content,
  videoId,
  titleTextColor,
}) => {
  return (
    <Box {...sectionWrapper} as="section">
      <Container>
        <Box {...secTitleWrapper}>
          <Text
            color={titleTextColor ? 'white' : '#2aa275'}
            {...secText}
            content={content}
          />
          <Vimeo video={videoId} responsive={true} />
        </Box>
      </Container>
    </Box>
  );
};

VimeoVideoSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
};

VimeoVideoSection.defaultProps = {
  sectionWrapper: {
    pt: ['15px', '15px', '15px', '15px', '15px'],
    pb: 0,
  },
  secTitleWrapper: {
    mb: ['30px', '50px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '18px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['14px', '17px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67',
  },
};

export default VimeoVideoSection;
