import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import { saasModernTheme } from 'common/theme/saasModern';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from 'containers/SaasModern/sassModern.style';

import BannerSection from 'containers/SaasModern/Banner';
import Navbar from 'containers/SaasModern/Navbar';
import WorkingProcessSection from 'containers/SaasModern/WorkingProcess';
import PricingSection from 'containers/SaasModern/Pricing';
import PartnerSection from 'containers/SaasModern/Partner';
import FaqSection from 'containers/SaasModern/Faq';
import TrialSection from 'containers/SaasModern/Trial';
import InfoSection from 'containers/SaasModern/Info';
import FeatureSection from 'containers/SaasModern/Feature';
import UpdateScreen from 'containers/SaasModern/UpdateScreen';
import TestimonialSection from 'containers/SaasModern/Testimonial';
import Footer from 'containers/SaasModern/Footer';
import SEO from 'components/seo';
import { Modal, Form, Button, Input, notification } from 'antd';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import KimlerIcinSection from '../containers/SaasModern/KimlerIcin';
import HakkimizdaSection from '../containers/SaasModern/Hakkimizda';
import KimlerIcinDegilSection from '../containers/SaasModern/KimlerIcinDegil';
import VimeoVideoSection from '../containers/SaasModern/VimeoVideo';
export default () => {
  const [exitIntentForm] = Form.useForm();
  const openExitModal = () => {
    const onFinish = () => {
      exitIntentForm
        .validateFields()
        .then((values) => {
          axios
            .post(
              'https://api.saderandevu.com/api/auth/send-exit-intent-modal',
              { ...values }
            )
            .then((response) => {
              if (response.data.code === 1) {
                notification.success({
                  message: 'Başarılı!',
                  description:
                    'Başarıyla mesajınızı aldık, en kısa süre içerisinde size dönüş yapacağız!',
                  placement: 'bottomRight',
                });
              }
            })
            .catch((err) => console.log(err.response.request));
        })
        .catch((info) => {
          console.log('Validate Failed:', info);
        });
    };
    const contentModal = () => {
      return (
        <Form form={exitIntentForm} name="basic">
          <Form.Item
            label="Firma Adı"
            name="businessName"
            rules={[
              {
                required: true,
                message: 'Lütfen firma adınızı yazınız!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Adınız Soyadınız"
            name="customerName"
            rules={[
              {
                required: true,
                message: 'Lütfen adınızı soyadınızı yazınız!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Telefon Numarası"
            name="customerPhone"
            rules={[
              {
                required: true,
                message: 'Lütfen telefon numaranızı yazınız!',
              },
            ]}
          >
            <PhoneInput
              country={'tr'}
              enableSearch={true}
              countryCodeEditable={false}
            />
          </Form.Item>
        </Form>
      );
    };
    Modal.info({
      title: 'Sizi Arayalım',
      content: contentModal(),
      okText: 'Gönder',
      onOk: onFinish,
      onCancel: exitIntentForm.resetFields(),
      maskClosable: true,
      closable: true,
      icon: null,
    });
  };
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="Randevu, Ciro, Müşteri Yönetim ve Pazarlama Yazılımı" />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <WorkingProcessSection />
          <KimlerIcinSection />
          <KimlerIcinDegilSection />
          <PartnerSection />
          <InfoSection />
          <FeatureSection />
          <UpdateScreen />
          <PricingSection />
          <TestimonialSection />
          <VimeoVideoSection
            content={"Dr. Berk Erol'un Deneyimi"}
            videoId={596430205}
          />
          <VimeoVideoSection
            content={"Atölye Vera Güzellik Salonu'nun Deneyimi"}
            videoId={650592699}
          />
          {/* <VimeoVideoSection
            content={"Maya Beauty Güzellik Salonu'nun Deneyimi"}
            videoId={868696665}
          /> */}
          <VimeoVideoSection
            content={"By Sadettin Kuaför'ün Deneyimi"}
            videoId={628266946}
          />
          <VimeoVideoSection
            content={"Diş Hekimi Bülent Koyuncu'nun Deneyimi"}
            videoId={774942067}
          />
          <VimeoVideoSection
            content={"Göztepe Güzellik Salonu'nun Deneyimi"}
            videoId={775505175}
          />
          <VimeoVideoSection
            content={"Emel Urcan Güzellik Merkezi'nin Deneyimi"}
            videoId={666306941}
          />
          <FaqSection />
          <HakkimizdaSection />
          <TrialSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
